<template>
    <div>
        <newHeader url="/WuliuIndex"></newHeader>
        <div class="title-box-1">
            BUSINESS LEASE INFORMATION
        </div>
        <div class="title-box-2">
            <img src="../assets/images/WuliuIndexTitle.png" height="31" width="251" class="img-title"/>
            <div class="img-title">
            </div>
        </div>
        <div class="car-box1">
            <nav class="car-info">全部车型</nav>
            <div class="car-box">
                <div v-for="(car,key) in cars" :key="key" class="car-box-card">
                    <div class="car-box-card-bg">
                        <strong class="car-name" v-text="carsName[key]"></strong>
                        <el-image
                                style="height: 260px"
                                :src="car"
                                fit="scale-down"></el-image>
                        <a href="javascript:;" class="car-btn" @click="lookCarInfo(key)">更多信息
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="brand-friend">
            <nav class="car-info">合作厂商</nav>
            <div class="brand-friend-box">
                <div v-for="(brand,key) in brandsImg" :key="key" class="brand-box">
                    <el-image
                            style="width: 200px; height: 170px"
                            :src="brand"
                            fit="contain"></el-image>
                </div>
            </div>

        </div>
        <div class="address-container">
            <nav class="car-info">欢迎咨询！</nav>
            <div class="address-container-box">
                <div class="address-container-box-right">
                    <baidu-map class="bm-view" :center="map.center" :zoom="map.zoom" :scroll-wheel-zoom="true"
                               :map-style="mapStyle">
                        <bm-marker :position="map.center" @click="infoWindowOpen" :offset="{width: 0,height:40}">
                            <bm-info-window :show="map.show" @close="infoWindowClose" @open="infoWindowOpen">
                                {{map.address}}
                            </bm-info-window>
                        </bm-marker>
                    </baidu-map>
                </div>
                <div class="address-container-box-left">
                    <div><img src="../assets/images/icon/info.png" height="16" width="16"/>车辆业务具体信息</div>
                    <div><img src="../assets/images/icon/link.png" height="16" width="16"/>联系人：胡同涛</div>
                    <div><img src="../assets/images/icon/tel.png" height="14" width="14"/>电话：13709425770</div>
                    <div><img src="../assets/images/icon/adress.png" height="16" width="14"/>地址：重庆市花园路街道桃源一路11号国泰汽车E电园
                    </div>
                </div>
            </div>
        </div>
        <buttom></buttom>
        <el-dialog :visible.sync="carInfoFlag" >
            <h1 class="carTitle"> {{carsName[index]}}</h1>
            <div style="text-align: center">
                <ul class="car-info-box">
                    <li class="car-info-td" v-for="(i,j) in carsInfo[index]" :key="j">
                        <div class="td-pre">
                            {{i.title}}
                        </div>
                        <div class="td-after">
                            {{i.value}}
                        </div>
                    </li>
                </ul>
            </div>
<!--            <el-image-->
<!--                    style="height: 800px"-->
<!--                    :src="cars[index]"-->
<!--                    fit="scale-down"></el-image>-->
        </el-dialog>
    </div>
</template>

<script>
    import newHeader from "@/components/common/newHeader";
    import Buttom from "@/components/common/Buttom";
    import Swipper from "@/components/common/index/Swipper";

    const mapS = require('./custom_map_config');
    const path = require('path');
    const brands = require.context('@/assets/images/carBrand/', false, /.png$/);
    const cars = require.context('@/assets/images/bigCar/', false, /.png$/);
    export default {
        name: "WuliuIndex",
        data: function () {
            return {
                map: {
                    center: {lng: 106.550653, lat: 29.519831},
                    zoom: 20,
                    address: '重庆市南岸区桃源一路国泰汽车e电园',
                    show: true,
                },
                mapStyle: {
                    styleJson: mapS
                },
                brandsImg: [],
                cars: [],
                carsName: [],
                carsInfo: [],
                carInfoFlag: false,
                index: 0
            }
        },
        components: {
            newHeader,
            Buttom,
            Swipper,
        },
        methods: {
            infoWindowClose() {
                this.map.show = false;
            },
            infoWindowOpen() {
                this.map.show = true;
            },
            lookCarInfo(key) {
                this.index = key
                this.carInfoFlag = true;
            }
        }
        ,
        mounted() {
            brands.keys().forEach(key => {
                this.brandsImg.push(require('@/assets/images/carBrand/' + path.basename(key)))
            })
            cars.keys().forEach(key => {
                this.cars.push(require('@/assets/images/bigCar/' + path.basename(key)))
                this.carsName.push(path.basename(key, '.png'))
                let box = []
                var obj = require('@/assets/carInfo/' + path.basename(key, '.png') + '.json')
                for (var key in obj) {
                    box.push({title: key, value: obj[key]})
                }
                this.carsInfo.push(box)
            })
        }
    }
</script>

<style lang="stylus" scoped>
    .car-info-box {
        display inline-block
        padding 0 1%
    }

    .carTitle {
        color: #fff;
        font-size: 30px;
        letter-spacing: 4px;
        text-align center
    }

    .td-pre {
        background-image: url("../assets/images/td-b.png");
        background-size 100% 100%
        width 200px
        height 50px
        line-height 50px
        display inline-block
        color #98b3f3
        font-size 16px
        text-align center
    }

    .td-after {
        background-image: url("../assets/images/td-w.png");
        background-size 100% 100%
        width 312px
        height 50px
        line-height 50px
        display inline-block
        color #ffffff
        font-size 16px
        text-align center

    }

    .car-info {
        text-align center;
        color rgb(255, 255, 255);
        padding 2%;
        font-size 26px;
        letter-spacing 2px
    }

    .long-text {
        color #6872a9
        font-size 12px
        width 60%
        white-space pre-wrap;
    }

    .car-text-box {
        background-image: url("../assets/images/car-text-box.png");
        background-size 100% 100%
        width 303px
        height 48px
        margin-top 20px
        line-height 48px
        font-size 18px
        color #fff
        padding-left 20px
    }

    .car-circle {
        display inline-block
        width 112px
        height 112px
        background-image: url("../assets/images/car-circle.png");
        background-size 100% 100%
        text-align center
        margin-right 40px

        nav {
            margin-top 33px;
            margin-left 3px;
            color #fff
            font-size 18px
        }

        p {
            margin-left 3px;
            color #8b9fe6
            font-size 10px
        }
    }

    .car-box1 {
        background-image: url("../assets/images/apic2261.png");
        background-size 100% 100%
        width 100%;
        padding 0 2rem
    }

    .car-box
        display flex
        align-items center
        justify-content center
        flex-wrap wrap

    .car-box-card {
        width 403px
        height 440px
        padding 2rem
    }

    .car-box-card-bg {
        background-color #151515
        height 100%
        width 100%
        padding-top: 20px;
        border: 1px solid #262626;
        text-align center
    }

    .car-btn:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #fff;
        content: '';
    }

    .car-btn:hover {
        background-color #fff
        color #000
        border: 1px solid #151515;
    }

    .car-btn {
        overflow: hidden;
        display: inline-block;
        position: relative;
        padding: 0 40px;
        padding: 0 4rem;
        background: #111;
        font-size: 18px;
        font-size: 1.8rem;
        height: 56px;
        height: 5.6rem;
        line-height: 58px;
        line-height: 5.8rem;
        color: #fff;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        vertical-align: top;
    }

    .car-name {
        text-align: center;
        display: block;
        font-family: "genesis-head-office-light", sans-serif;
        font-weight: normal;
        font-size: 30px;
        font-size: 3rem;
        letter-spacing: -0.025em;
        line-height: 38px;
        line-height: 3rem;
        color: #fff;
    }

    .title-box-1 {
        height 200px;
        font-size 52px;
        color #f5f7fc;
        line-height 200px;
        z-index -999;
        position absolute;
        width 100%;
        letter-spacing 5px
        text-align center
    }

    .title-box-2 {
        padding 100px 0
        text-align center
    }

    .img-title:after {
        content '';
        width 50px;
        height 1px;
        display block;
        margin 0 auto
        border-bottom 3px solid #1a4fcd;
    }

    .car-type:after {
        content 'NEW ENERGY';
        margin 0 auto
        height 1px;
        display block;
        color rgba(255, 255, 255, 0.1)
        font-size 52px;
        position relative;
        top -65px
    }

    .car-type2:after {
        content 'EM60';
        margin 0 auto
        height 1px;
        display block;
        color rgba(255, 255, 255, 0.1)
        font-size 62px;
        position relative;
        top -65px
    }

    .wuliu-container
        width 100%
        height auto
        box-sizing border-box
        padding-top 2rem

    .wuliu-container-box
        width 100rem
        display flex
        margin 0 auto
        flex-direction row
        justify-content space-around
        align-content center
        position relative

        img
            width 100%
            height 100%

        .el-button
            position absolute
            bottom 10%
            right 10%

    .brand-friend
        width 100%
        height auto
        box-sizing border-box
        padding-bottom 2rem
        background #05071e

        .brand-friend-box
            display flex
            align-items center
            justify-content space-evenly

            .brand-box
                background #ffffff
                padding 20px
                display inline-block


    .address-container
        width 100%
        height auto
        box-sizing border-box
        padding-bottom 2rem
        background #05071e

        .address-container-box
            width 110rem
            height 20rem
            display flex
            margin 10px auto
            flex-direction row
            justify-content space-around
            align-content center

            .address-container-box-left
                margin-right 10px;
                width 50%;
                height 100%;
                float left;
                background #130e5c;
                line-height 30px;
                color #8fabeb;
                font-size 16px;
                padding-top 45px;
                padding-left 45px;

                img {
                    vertical-align middle
                    margin-right 12px
                }

            .address-container-box-right
                margin-left 10px
                width 50%
                height 100%
                float left

                .bm-view
                    width 100%
                    height 100%

    >>> .anchorBL {
        display none
    }

    >>> .el-dialog__header {
        padding: 20px 20px 10px;
        background: #060a22;
    }

    >>> .el-dialog__body {
        background: #060a22;
    }

    >>> .el-dialog {
        border 1px solid #fff
    }

</style>
